import {Col, Form, Row} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import {isMexico, useInputDataState, useLabels} from '../../functions'
import CreditCard from './CreditCard'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {connect} from '../Notes/wrapper'
import {ChargesExplained} from './PreviewOrder'

export const MXPaymentMethod = () => {
    const labels = useLabels()
    const [cashOrCreditCard, setCashOrCreditCard] = useInputDataState('cashOrCreditCard')
    const [reasonNotCC, setReasonNotCC] = useInputDataState('reasonNotCC')
    const nonPCI = useSelector(state => state.settings.nonPCI)

    const methods = [{
        key: 'El cliente ingresó la información de su propia tarjeta',
        value: 'customer submitted CC',
    },
    ]
    if (!nonPCI) {
        methods.push({key: 'Tarjeta de credito o debito', value: 'Tarjeta de credito o debito',})
    }
    methods.push({key: 'Efectivo', value: 'Efectivo',})
    methods.push({key: 'Efectivo al instalador', value: 'Efectivo al instalador',})

    const whyCashOptions = [
        'N/A',
        'Desconfianza en la empresa',
        'Desconfianza de pagar con CC',
        'La cliente no tiene tarjeta de credito',
        'La tarjeta de credito no tiene suficiente cantidad',
        'Tarjeta de credito bloqueada para compras en linea',
        'Problemas en IVR',
    ]

    return <>
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel bold={true}>{labels.paymentMethod}</FormLabel>
                    {
                        methods.map(e => <FormCheck type="radio" key={e.key} value={e.value}
                                                    checked={cashOrCreditCard === e.value}
                                                    label={e.key}
                                                    onChange={setCashOrCreditCard}/>)
                    }
                </Form.Group>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel required={true}>{labels.whyCash}</FormLabel>
                    <Form.Select onChange={setReasonNotCC} value={reasonNotCC}>
                        <option></option>
                        {whyCashOptions.map(e => <option value={e}>{e}</option>)}
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
        <ChargesExplained/>
    </>
}

export const PaymentSwitch = () => {
    const cashOrCreditCard = useSelector(state => state.customer.data.cashOrCreditCard)
    const program = useSelector(state => state.customer.data.program)


    if (!isMexico(program)) {
        return <CreditCard/>
    }

    let component = <CreditCard/>
    if (cashOrCreditCard.toLowerCase().includes('efectivo')) {
        component = null
    }

    const Wrapper = connect(MXPaymentMethod, 'payment-types')

    return <>
        <Wrapper/>
        {component}
    </>
}
